import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import styled from "styled-components";
import "./cookies.css";

const Despre = () => {
  return (
    <Layout>
      <Seo title="POLITICA DE UTILIZARE COOKIE-URI" />
      <Stele></Stele>
      <Wrapper>
        <Title>POLITICA DE UTILIZARE COOKIE-URI</Title>

        <Subtitle>
          <h3>1. Ce sunt Cookie-urile?</h3>
          <p>
            Cookie-ul este un fisier text de mici dimensiuni, format din litere
            si numere, care va fi stocat pe computerul, terminalul mobil sau
            alte echipamente ale unui utilizator de pe care se acceseaza
            site-urile web. Cookie-ul este instalat prin solicitarea emisa de
            catre un server catre un browser (ex: Internet Explorer, Chrome) si
            este complet “pasiv” (nu contine programe software, virusi sau
            spyware si nu poate accesa informatiile de pe hard driverul
            utilizatorului).
          </p>
          <p>
            Aceasta tehnologie este foarte raspandita si se foloseste pentru a
            colecta informatii precum tipul de browser si sistemul de operare,
            pagina sursa, denumirea domeniului din care vizitatorul s-a conectat
            la site-ul GARLIC bites&amp;tales etc., cu scopul de a intelege cum
            folosesc vizitatorii aceasta pagina de web.
          </p>
          <p>
            <span>Cookie-uri esențiale</span> sunt necesare pentru ca website-ul
            să funcționeze, spre exemplu permit unui vizitator să rămână
            autentificat în contul propriu.
          </p>
          <p>
            <span>Cookie-uri de performanță</span> contribuie la îmbunătățirea
            accesibilității acestui website, oferind o mai bună experiență de
            navigare a utilizatorului.
          </p>
          <p>
            <span>Cookie-uri per sesiune</span> sunt fișiere temporare ce rămân
            în terminalul utilizatorului până la terminarea sesiunii sau
            închiderea aplicației.
          </p>
          <p>
            <span>Cookie-uri fixe / permananete</span> rămân pe terminalul
            utilizatorului pe o perioadă determinată de parametrii cookie-ului
            sau până sunt șterse manual de utilizator.
          </p>

          <h3>2. Datele cu caracter personal incluse in cookie-uri</h3>
          <p>
            Cookie-urile in sine nu solicita date cu caracter personal pentru a
            putea fi utilizate si, in multe cazuri, nici nu identifica personal
            utilizatorii de Internet. Exista insa situatii cand datele personale
            pot fi colectate prin utilizarea Cookie-urilor pentru a facilita
            anumite functionalitati pentru utilizator sau pentru a oferi
            utilizatorului o experienta mai adaptata preferintelor sale.
          </p>
          <p>
            Datele personale legate de cookie-uri sunt prelucrate in principal
            pentru scopuri legate de mentinerea si dezvoltarea infrastructurii
            IT, a website-urilor operate de GARLIC bites&amp;tales si pot fi
            folosite pentru adaptarea continutului acestora la specificul
            utilizatorului. In subsidiar, alte scopuri pot fi relevante, precum
            protectia juridica a societatii noastre.
          </p>

          <h3>3. Cum folosim cookie-urile?</h3>
          <p>
            Cookie-urile fac posibila in principal recunoasterea terminalului
            utilizatorului si prezentarea continutului intr-un mod relevant,
            adaptat preferintelor utilizatorului in functie de profilurile
            identificate.
          </p>
          <p>
            Folosim cookie-uri pentru a imbunatati utilizarea si
            functionalitatea site-ului nostru si pentru a intelege mai bine
            modul in care vizitatorii utilizeaza acest site, precum si
            serviciile oferite de catre acesta. Stocarea de cookie-uri pe
            calculatorul dumneavoastra facilitează personalizarea sau
            imbunatatirea experientei dumneavoastra pe site-ul noastru.
          </p>
          <p>
            Tabelul de mai jos descrie fiecare cookie utilizat pe website,
            inclusiv rolul acestuia. Nu vom utiliza aceste cookie-uri în alte
            moduri decât cele indicat în prezenta Politică de utilizare
            cookie-uri.
          </p>
          <br />
          <div>
            <table>
              <tr>
                <th>
                  <p>Denumire cookie</p>
                </th>
                <th>
                  <p>Categorie</p>
                </th>
                <th>
                  <p>Rol / Descriere</p>
                </th>
                <th>
                  <p>Strict necesar /</p>
                </th>
                <th>
                  <p>
                    Durata
                    <br />
                    (e.g. sesiune / permanent etc.)
                  </p>
                </th>
              </tr>
              <tr>
                <td>
                  <p>_ga</p>
                </td>
                <td>
                  <p>Performanta</p>
                </td>
                <td>
                  <p>Diferentiaza utilizatorii</p>
                </td>
                <td>
                  <p>da</p>
                </td>
                <td>
                  <p>2 ani</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_gat</p>
                </td>
                <td>
                  <p>Performanta</p>
                </td>
                <td>
                  <p>Imbunatatirea performantei</p>
                </td>
                <td>
                  <p>da</p>
                </td>
                <td>
                  <p>10 minute</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>_gid</p>
                </td>
                <td>
                  <p>Performanta</p>
                </td>
                <td>
                  <p>Diferentiaza utilizatorii</p>
                </td>
                <td>
                  <p>da</p>
                </td>
                <td>
                  <p>24 de ore</p>
                </td>
              </tr>
            </table>
          </div>
          <br />

          <h3>4. Cookie dedicat pentru prezenta Politica</h3>
          <p>
            In vederea inregistrarii informației privind vizualizarea mesajului
            despre prezenta Politica de utilizare a fisierelor cookies vom
            folosi un cookie dedicat. La prima accesare a website-ului nostru,
            veti fi primi un mesaj de tip pop-up care ofera informatii succinte
            despre utilizarea fisierelor cookies. Dacă apasati butonul de
            acceptare, un fisier cookie va fi stocat în dispozitivul
            dumneavoastra care va retine faptul ca ati vazut mesajul, ati fost
            de acord cu prezenta Politica si la accesarea ulterioara a
            website-ului de catre dumneavoastra mesajul pop-up va inceta sa
            apara.
          </p>

          <h3>5. Acordarea consimtamantului pentru folosirea cookie-urilor</h3>
          <p>
            La accesarea acestui site, ati avut posibilitatea de a va da
            consimtamantul pentru folosirea cookie-urilor. Acceptarea
            cookie-urilor vă va oferi o experienta adaptata pentru preferintele
            dumneavoastra, mult imbunatatita fata de cea standard.{" "}
            <strong>
              Puteti, insa, in orice moment, sa va retrageti consimtamantul
              acordat dezactivand cookie-urile conform instructiunilor de mai
              jos
            </strong>
            , moment din care cookie-urile respective nu vor mai fi folosite in
            relatia cu dumneavoastra.
          </p>
          <p>
            Cookie-urile ajuta la generarea unei experiente de navigare
            prietenoase si adaptata preferintelor si intereselor fiecarui
            utilizator. Refuzarea sau dezactivarea anumitor cookie-urilor poate
            face unele site-uri sau parti de site-uri imposibil de folosit.
          </p>

          <h3>
            6. Ce trebuie să faceți dacă nu doriți să permiteți utilizarea de
            cookie-uri in general
          </h3>
          <p>
            In general, o aplicatie folosita pentru accesarea paginilor web
            permite salvarea cookie-urilor pe terminal in mod implicit si
            stocarea pe perioadele descrise in tabelul de mai sus. Aceste setari
            pot fi schimbate in asa fel incat administrarea automata a
            cookie-urilor sa fie blocata de browser-ul web sau utilizatorul sa
            fie informat de fiecare data cand cookie-urile sunt trimise catre
            terminalul sau. Informatii detaliate despre posibilitatile si
            modurile de administrare a cookie-urilor pot fi gasite in zona de
            setari a aplicatiei (browser-ului web), in modalitatea detaliata mai
            jos.
          </p>
          <p>
            Limitarea folosirii cookie-urilor poate afecta anumite
            functionalitati ale paginii de Internet. Exemple de intrebuintari
            importante ale cookie-urilor (care nu necesita autentificarea unui
            utilizator prin intermediul unui cont):
          </p>
          <ul>
            <li>
              Continut si servicii adaptate preferintelor utilizatorului –
              categorii de produse si servicii
            </li>
            <li>
              Oferte adaptate pe interesele utilizatorilor – retinerea parolelo
            </li>
            <li>
              Retinerea filtrelor de protectie a copiilor privind continutul pe
              Internet (optiuni family mode, functii de safe search)
            </li>
            <li>
              Limitarea frecventei de difuzare a reclamelor – limitarea
              numarului de afisari a unei reclame pentru un anumit utilizator pe
              un site
            </li>
            <li>Furnizarea de publicitate mai relevanta pentru utilizator</li>
            <li>
              Masurarea, optimizarea si adaptarea caracteristicilor de analiza –
              cum ar fi confirmarea unui anumit nivel de trafic pe un website,
              ce tip de continut este vizualizat si modul cum un utilizator
              ajunge pe un website (ex. prin motoare de cautare, direct, din
              alte website-uri etc). Website-urile deruleaza aceste analize a
              utilizarii lor pentru a imbunatati site-urile in beneficiul
              utilizatorilor
            </li>
          </ul>
          <p>
            Toate browserele moderne ofera posibilitatea de a schimba setarile
            cookie-urilor. Aceste setari se gasesc de regula in “
            <span>optiuni</span>” sau in meniul de “<span>preferinte</span>” al
            browserului dumneavoastra.
          </p>
          <p>
            <strong>
              Microsoft Internet Explorer 8/9 (sistem de operare Windows 7)
            </strong>
          </p>
          <ul>
            <li>
              fă click pe pictograma <span>Setări</span> (dreapta sus)
            </li>
            <li>
              fă click pe <span>Opţiuni Internet</span>
            </li>
            <li>
              click pe <span>Confidenţialitate</span> şi apoi alege opţiunile
            </li>
            <li>
              click pe Aplicare şi apoi pe OK pentru salvarea preferinţelor
            </li>
          </ul>
          <p>
            <strong>Google Chrome</strong>
          </p>
          <ul>
            <li>
              fă click pe pictograma cheie şi selectează <span>Opţiuni</span>{" "}
              din listă
            </li>
            <li>
              click pe tabul ”<span>Setari avansate</span>”
            </li>
            <li>
              fă click pe <span>Setări</span> privind conținutul din secțiunea
              Confidențialitate
            </li>
            <li>alege setările pe care le doreşti</li>
            <li>click pe X pentru a închide</li>
          </ul>
          <p>
            <strong>Safari</strong>
          </p>
          <ul>
            <li>
              alege <span>Preferinţe</span>
            </li>
            <li>fă click pe panoul de confidentialitate</li>
            <li>alege setările pe care le doreşti</li>
            <li>click pe X pentru a închide</li>
          </ul>
          <p>
            <strong>Mozilla Firefox</strong>
          </p>
          <ul>
            <li>
              selectează <span>Instrumente</span> din bara principala, apoi{" "}
              <span>Opţiuni</span>
            </li>
            <li>
              click pe tabul <span>Confidenţialitate</span>
            </li>
            <li>alege setările pe care le doreşti</li>
            <li>click pe OK pentru a închide</li>
          </ul>
          <p>
            <strong>Google Analytics</strong>
          </p>
          <p>
            Pentru evaluarea utilizarii site-ului folosim Google Analytics.
            Google Analytics retine informatii despre paginile vizitate, timpul
            pe care îl petreceti pe site, modalitatea prin care ati ajuns aici
            si elementele accesate. Nu colectam si nu stocam date personale (de
            exemplu, numele sau adresa dumneavoastră), astfel ca aceste
            informații nu pot fi folosite pentru a va identifica.
          </p>
          <p>
            Puteti opta pentru renuntarea la acele cookie-uri introduse de
            Google Analytics, vizitand aceasta pagina{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </p>

          <h3>7. Securitate si aspecte legate de confidentialitate</h3>
          <p>
            Cookie-urile NU sunt virusi! Nu sunt alcatuite din bucati de cod asa
            ca nu pot fi executate, nici nu pot auto-rula. In consecinta, nu se
            pot duplica sau replica pe alte retele pentru a se rula sau replica
            din nou. Deoarece nu pot indeplini aceste functii, nu pot fi
            considerate virusi.
          </p>
          <p>
            Unele cookie-uri pot fi totusi folosite pentru scopuri negative.
            Deoarece stocheaza informatii despre preferintele si istoricul de
            navigare al utilizatorilor, atat pe un anume site cat si pe mai
            multe alte site-uri, cookie-urile pot fi folosite ca o forma de
            Spyware. Multe produse anti-spyware sunt constiente de acest fapt si
            in mod constant marcheaza cookie-urile pentru a fi sterse in cadrul
            procedurilor de stergere/ scanare anti-virus/ anti-spyware.
          </p>
          <p>
            In general browserele au integrate setari de confidentialitate care
            furnizeaza diferite nivele de acceptare a cookie-urilor, perioada de
            valabilitate si stergere automata dupa ce utilizatorul a vizitat un
            anumit site.
          </p>

          <h3>8. Alte aspecte de securitate legate de cookie-uri</h3>
          <p>
            Deoarece protectia identitatii este foarte valoroasa si reprezinta
            dreptul fiecarui utilizator de Internet, este indicat sa se stie ce
            eventuale probleme pot crea cookie-urile. Pentru ca prin intermediul
            lor se transmit in mod constant in ambele sensuri informatii intre
            browser si website, daca un atacator sau persoana neautorizata
            intervine in parcursul de transmitere a datelor, informatiile
            continute de cookie pot fi interceptate. Desi foarte rar, acest
            lucru se poate intampla daca browserul se conecteaza la server
            folosind o retea necriptata (ex: o retea WiFi nesecurizata).
          </p>
          <p>
            Alte atacuri bazate pe cookie implica setari gresite ale
            cookie-urilor pe servere. Daca un site de Internet nu solicita
            browser-ului sa foloseasca doar canale criptate, atacatorii pot
            folosi aceasta vulnerabilitate pentru a pacali browserele in a
            trimite informatii prin intermediul canalelor nesecurizate.
            Atacatorii utilizeaza apoi informatiile in scopuri de a accesa
            neautorizat anumite site-uri.
          </p>

          <h3>9. Linkuri si informatii suplimentare utile</h3>
          <p>
            Daca doriti sa afli mai multe infomatii despre cookie-uri si la ce
            sunt utilizate, recomandam urmatoarele linkuri:
          </p>
          <p>
            Microsoft Cookies guide
            <br />
            All About Cookies<br></br>
            <a
              href="http://www.youronlinechoices.com/ro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.youronlinechoices.com/ro/
            </a>
          </p>
        </Subtitle>
      </Wrapper>
    </Layout>
  );
};

export default Despre;
const Strong = styled.text`
  font-weight: 600;
  opacity: 1;
`;
const Stele = styled.div`
  background-image: url("/images/backgrounds/fundal.png");
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0%;
  z-index: -1;
`;
const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin: 10px;
  margin-top: 100px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
`;

const Title2 = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;
  color: #ffffff;
  opacity: 0.6;
  max-width: 800px;
  margin: auto;
`;
